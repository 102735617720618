export const beforeSendSentryHook = (event: any, hint: any) => {
    if (hint) {
        const errorMessage = hint.originalException || '';

        const chunkErrorRegex = /Error page with error: Loading chunk (\d+) failed./;
        const match = (errorMessage as String).match(chunkErrorRegex);

        if (match) {
            const chunkNumber = match[1];

            event.message = "Error page with error: Loading chunk failed.";

            event.tags = {
                ...event.tags,
                chunkNumber: chunkNumber,
            };
        }
    }

    return event;
}

